

export class BenefitListSearch {
  SupplierCode: string;
  StartDate: string;
  EndDate: string;
  Period: string = "0";
  NoofColumn: number;

}

export class BenefitReportClaimListSearch {
  Benefit: string = "0";
  CategoryNameList: string[];
  AgmNos: string[];
  SupplierName: string = "";
  SupplierCode: string = "";
  CreateBy: string = "0";
  Period: string = "0";
  StartDate: string = "";
  EndDate: string = "";
  Status: number = 300;
}


export class BenefitReport {
  AgmId: number = null;
  SupplierCode: string = null;
  SupplierName: string = null;
  AgmNo: string = null;
  StartDate: Date = null;
  EndDate: Date = null;
  StatusCode: string = null;
  CreateBy: number = null;
  CreateDate: Date = null;
  UpdateBy: number = null;
  UpdateDate: Date = null;
  BenefitRebate: BenefitDetail = new BenefitDetail();
  BenefitDcFee: BenefitDetail = new BenefitDetail();
  BenefitMarketingFee: BenefitDetail = new BenefitDetail();
  Status: number = null;
  StatusReport: number = null;
  Statusbgcolor: string = null;
  Statusbordercolor: string = null;
  Statusfontcolor: string = null;
  Statusfontsize: number = null;
  CreateByName: string = null;
  UpdateByName: string = null;
  CheckAttachment: any = null;
}

export class BenefitDetail {
  invoiceAttachments: AgmInvoiceAttachment[] = [];
  isInvoiceIssued: boolean = false;
  Status: number = 0;
  AgmBenefitId: number = 0;
  AgmId: number = 0;
  Period: string = null;
  PeriodName: string = null;
  CreateBy: number = 0;
  CreateDate: Date = null;
};

export class BenefitTypeList {
  BenefitTypeId: number;
  Description: string;
}

export class BenefitInvoiceModel {
  AgmId: number = null;
  AgmBenefitId: number = null;
  BenefitTypeId: number = null;
  PeriodName: string = null;
}

export class BenefitInvoiceFileModel {
  AgmId: number;
  AgmNo: any;
  Description: any;
  StartDate: string;
  EndDate: string;
  BenefitTypeId: number;
  BenefitTypeDescription: string;
  AgmBenefitId: number;
  AgmBenefitAttachments: AgmBenefitPeriod = new AgmBenefitPeriod();
}

export class AgmBenefitPeriod {
  PeriodCode: string;
  Period: AgmInvoiceAttachment[] = [];
}

export class AgmInvoiceAttachment {
  Id: number;
  AgmId: number;
  BenefitTypeId: number;
  AgmBenefitId: number;
  InvoicePeriod: string;
  Path: any;
  Description: any;
  FileName: any;
  UploadBy: number;
  UploadDate: string;
  DelFlag: boolean;
  Reason: string;
  IsNoInvoice: boolean;
}


export class Root {
  AgmHeaderList: AgmHeaderList[]
  AgmCreditList: AgmCreditList[]
  SupplierInfo: SupplierInfo
  GroupFullAgm4ClaimBenefit: GroupFullAgm4ClaimBenefit[]
}

export class AgmHeaderList {
  AgmID: number
  AgmNo: string
  StartDate: string
  EndDate: string
  IncVat: boolean
  AgmType: string
}

export class AgmCreditList {
  AgmNo: string
  Table: Table[]
  NoofColumn: number
}

export class Table {
  AgmNo: string
  Year: string
  Period: string
  CateCode: string
  CategoryName: string
  SubCateCode: string
  SubCategoryName: string
  Value1: number
  Value2?: number
  Value3: any
  Value4: any
  Value5: any
  Value6: any
  Value7: any
  Value8: any
  Value9: any
  Value10: any
  Value11: any
  Value12: any
  NoofColumn: number
}

export class SupplierInfo {
  SupplierId: number
  SupplierCode: string
  SupplierName: string
  Saplastinterface: any
  Telephone1: any
  Email: any
}

export class BenefitHeaderModel {
  BenefitRequestID?: number;
  BenefitRequestDetailID?: number;
  BenefitTypeID?: number;
  BenefitNo: string;
  Note: string;
  SendSupplierFlag?: boolean;
  NoteBuyerVerifyReject: string;
  NoteSupplierReject: string;
  NoteToBuyer: string;
  NoteBuyerReject: string;
  BenefitStatus?: number;
  SupplierCode: string;
  CreateBy?: number;
  CreateDate?: Date;
  UpdateBy?: number;
  UpdateDate?: Date;
  CateApproverUserId?: number;
  SupApproverUserId?: number;
  Attachment: any[];
  AttachmentNew: any[];
}

export class GroupFullAgm4ClaimBenefit {
  Checked: boolean
  AgmNo: string
  data: Daum[]
}

export class Daum {
  AgmNo: string
  BenefitType: string
  RebateType: string
  RebateTypeName: string
  StartDate: Date
  EndDate: Date
  PeriodCode: Date
  PeriodBenefit: PeriodBenefit[]
}

export class PeriodBenefit {
  Checked: boolean
  Agm4ClaimBenefit: Agm4ClaimBenefit
  BenefitTable: BenefitTable[]
  NoofColumn: number
  PeriodIndex: number
  ItemSubCategoryList: ItemSubCategoryList[]
  Active: boolean
  BenefitStatus: number
  IsClaimable: boolean
}

export class Agm4ClaimBenefit {
  AgmID: number
  BenefitTypeId: number
  AgmBenefitId: number
  StartDate: Date
  EndDate: Date
  CreateBy: number
  CreateDate: Date
  UpdateBy: number
  UpdateDate: Date
  AgmNo: string
  RebateTypeName: string
  RebateType: string
  PeriodCode: string
  PeriodName: string
  NoofColumn: number
  IncVat?: boolean
  SupType: string
  CalBy: string
  PaymentMethodCode: string
  PaymentMethodName: string
  PeriodIndex: number
  ItemLineNo: number
  CategoryCode: string
  CategoryName: string
  SubCategoryCode: string
  SubCategoryName: string
  StepAmounts: string
  TargetAmounts: string
  CalName: string
  StepAmountArr: number[]
  TargetAmountsArr: number[]
  Table: any
  TotalActualOrder: number
  BenefitStatus: number
  Active: boolean
}

export class BenefitTable {
  StepAmount?: number
  TargetAmount?: number
  BenefitAmount?: number
  StepNo?: number
}

export class ItemSubCategoryList {
  AgmID: number

  // AgmRebateID: number
  BenefitTypeId: number
  AgmBenefitId: number

  AgmNo: string
  RebateTypeName: string
  RebateType: string
  PeriodCode: string
  PeriodName: string
  NoofColumn: number
  IncVat?: boolean
  SupType: string
  CalBy: string
  PaymentMethodCode: string
  PaymentMethodName: string
  PeriodIndex: number
  ItemLineNo: number
  CategoryCode: string
  CategoryName: string
  SubCategoryCode: string
  SubCategoryName: string
  StepAmounts: string
  TargetAmounts: string
  CalName: string
  StepAmountArr: number[]
  TargetAmountsArr: number[]
  Table: any
  TotalActualOrder: string
}
