export class POBookingDetail {
    BookingID: number;
    SupplierCode: string;
    SupplierName: string;
    SupplierTelephone: string;
    SupplierMail: string;
    SupplierAddress:string;
    IDCode: string;
    BookingNo: string;
    PlantCode: string;
    PlantName: string;
    GateCode: string;
    DCGateName: string;
    BookingDate
    BookingTime: string;
    BookingName: string;
    BookingTelephone: string;
    Note: string;
    StartTime: string;
    EndTime: string;
    StatusCode: string;
    StatusName: string;
    RejectedReason: string;
    RejectedBy?: number;
    RejectDate?: string;
    ApproveBy?: number;
    ApproveDate?: string;
    CreateBy?: number;
    CreateDate?: string;
    UpdateBy?: number;
    UpdateDate?: string;
    BookingDetailID?: number;
    MaterialNumber: string;
    POHeaderID?: number;
    POItemNumber: string;
    ProductPatternflag?: boolean;
    SAPPODocNumber: string;
    Qty?: number;
    Unit: string;
    Description: string;
  Barcode: string;
  DCName: any;
  IsApprove: boolean;
  SendEmail: boolean;
}
export class paramDPReport2 {
    preporttype: number;
    pvbeln: string;
    pebeln: string;
    pprintby: string;
    statusContract?: number;
}
export class DisplayRequest {
  DELIVERY_NUMBER: string;
  SALE_ORG: string;
  SUPPLIER_NO: string;
}

