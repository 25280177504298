export class ShiftProduction {
  ShiftID: number;
  ShiftCode: string;
  Shift: string;
  Description: string;
  Deleted: boolean = false;
  CreateDate: string;
  CreateBy: string;
  UpdateDate: string;
  UpdateBy: string;
}
export class DCGate {
  DCGateCode: string;
  DCGateName: string;
}
export class Supplier_DDL {
  SupplierId: number;
  SupplierCode: string;
  SupplierName: string;
}
export class StatusSupplier_DDL {
  SupplierId: number;
  SupplierCode: string;
  SupplierName: string;
}
export class TimeOfBooking {
  StartTime: string;
  EndTime: string;
}
export class PlantDC {
  PlantCode: string;
  PlantName: string;
  DCName: string;
}
export class LineProduction {
  LineID: number;
  Line: string;
  Description: string;
  Deleted: boolean;
  CreateDate: string;
  CreateBy: string;
  UpdateDate: string;
  UpdateBy: string;
}
export class Period {
  PeriodCode: string;
  PeriodName: string;
  Value: string;
  Unit: string;
  SystemFlag: boolean;
  CreateBy: string;
  CreateDate: string;
  UpdateBy: string;
  UpdateDate: string;
  NoofColumn: number;
}
export class SupportType {
  SupTypeCode;
  SupTypeName;
  Unit;
  SystemFlag;
  CreateBy;
  CreateDate;
  UpdateBy;
  UpdateDate;
}
export class CalculateBy {
  SystemFlag;
  CreateBy;
  CreateDate;
  UpdateBy;
  UpdateDate;
  CalByName: any;
  CalByCode: any;
}
export class PaymentMethod {
  PaymentMethodCode;
  PaymentMethodName;
  SystemFlag;
  CreateBy;
  CreateDate;
  UpdateBy;
  UpdateDate;
}
export class GetSupplierList {
  SupplierId?: number;
  SupplierCode: string;
  SupplierName: string;
  Saplastinterface?: string;
  Telephone1: string;
  Email: string;
}
export class MasterSearch {
  SupplierCode: string;
  SupplierName: string;
  Status: string;
}

export class GetSupplierBySupplierId {
  SupplierId?: number;
  Taxid: string;
  Suppliercode: string;
  SupplierName: string;
  Name1: string;
  Name2: string;
  Name3: string;
  Name4: string;
  Address: string;
  Street: string;
  City: string;
  District: string;
  PostalCode: string;
  Country: string;
  Telephone1: string;
  Telephone2: string;
  Paymentterm: string;
  Sendsupplierflag: boolean;
  Status?: number;
  Createby?: number;
  Createdate?: string;
  Updateby?: number;
  Updatedate?: string;
  Saplastinterface?: string;
  EmailNotification?: string;
}
export class UpdateSupplier {
  SupplierId?: number;
  Sendsupplierflag: boolean;
  Active?: number;
  EmailNotification?: string;
}

export class MenuConnectAnotherSystem {
  MenuConnectAnotherSystemID: number;
  ObjectID: string;
  MenuName: string;
  MenuIcon: string;
  URL: string;
  RequestCode: string;
  SecretKey: string;
  VendorCode: string;
  UserName: string;
  CreateDate: Date;
  CreateBy: number;
  UpdateDate: Date;
  UpdateBy: number;
  Active: boolean;
}
