import { isNumber } from "@ng-bootstrap/ng-bootstrap/util/util";
import { PaymentMethod, CalculateBy, SupportType, Period } from "./master.model";

export class TemplateList {
  ContractTemplateID: string;
  TemplateCode: string;
  Description: string;
  EffectiveDate: string;
  CreateBy: string;
  CreateDate: string;
  UpdateBy: string;
  UpdateDate: string;
  Transactiontype: string;
  StatusID: string;
  StatusCode: string;
  StatusName: string;
  Statusbgcolor: string;
  Statusbordercolor: string;
  Statusfontcolor: string;
  Statusfontsize: string;
  CreateByName: string;
  UpdateByName: string;
}

export class  AgmTempateAllData {
    PaymentMethod: PaymentMethod[];
    CalculateBy: CalculateBy[];
    SupportType: SupportType[];
    Period: Period[];
}


export class AgmTemplate {
    
  public AgmTemplateID: number;
  
  public TemplateCode: string;
  
  public Description: string;
  
  public EffectiveDate: string;
  
  public Status: number;
  
  public CreateBy: number;
  
  public CreateDate: string;
  
  public UpdateBy: number;
  
  public UpdateDate: string;
  
  public AgmTemplateDetail: Array<AgmTemplateDetail>;
  StatusName: any;
  StatusBgColor: any;
  StatusBorderColor: any;
  StatusFontColor: any;
  StatusFontSize: any;
  StatusCode: any;
}
export class AgmTemplateDetail {
  
  public AgmTemplateID: number;
  
  public TemplateCode: string;
  
  public Description: string;
  
  public EffectiveDate: string;
  
  public Status: number;
  
  public AgmTabCode: string;
  
  public AttrCode: string;
  
  public LineNo: number;
  
  public ddlCode: string;
  
  public ddlName: string;
  
  public ddlValue: string;
  
  public Unit: string;
  
  public NoofColumn: number;
  
  public StatusCode: string;
  
  public StatusBgColor: string;
  
  public StatusBorderColor: string;
  
  public StatusFontColor: string;
  
  public StatusFontSize: number;
}
export class TemplateList_Search {
  TemplateCode: string;
  Status: string;
  Description: string;
  StartEffectivedate: string;
  EndEffectivedate: string;
}
export class UpdateContractTemplateStatus {
  ContractTemplateID: string;
  Status: string;
}
export class TemplateAgreementList {
  AgmTemplateID:string;
  TemplateCode: string;
  Description: string;
  Effectivedate: string;
  Createbyname: string;
  Createdate: string;
  Updatebyname: string;
  Updatedate: string;
  Status: string;
  StatusCode: string;
  Statusbgcolor: string;
  Statusbordercolor: string;
  Statusfontcolor: string;
  Statusfontsize: number;
}
export class TemplateAgreementList_Search {
  Templatecode: string;
  Status: string;
  Effectivedatefrom: string;
  Effectivedateto: string;
  Description:string;
}
export class UpdateAgmTemplateStatus {
  AgmTemplateID: string;
  Status: string

}

export class  Getcontracttemplate{
    ContractTemplateID: number;
    TemplateCode: string;
    Description: string;
    Effectivedate: string;
    Createby: number;
    Createname: string;
    Createdate: string;
    Updateby: number;
    Updatename: string;
    Updatedate: string;
    Transactiontype: number;
    Statusid: number;
    Statuscode: string;
    Statusname: string;
    Statusbgcolor: string;
    Statusbordercolor: string;
    Statusfontcolor: string;
    Statusfontsize: number;
    GetContractTemplateDetail:GetcontracttemplateDetail[];
}

export class  GetcontracttemplateDetail{
    Id: string;
    ContractTemplateId: number;
    LineNo: number;
    Name: string;
    Description: string;
    IsHeaderFlag: boolean;
    IsFooterFlag: boolean;
    UserLevelCanEdit: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class ContractTemplate{
    ContractTemplateId:number;
    TemplateCode: string;
    Description: string;
    Effectivedate: string;
    Status: string;
    Createby: number;
    Updateby: number;
    ContractTemplateDetail: ContractTemplateDetail[];
}
export class ContractTemplateDetail
{
    ContractTemplateId: number;
    LineNo: number;
    Name: string;
    Description: string;
    Isheaderflag: boolean;
    Isfooterflag: boolean;
    Userlevelcanedit: string;
    Createby: number;
    Updateby: number;
}