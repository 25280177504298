import { SmartItem } from "./GoodsIssue.model";
import { SystemAttachDoc, Supplier } from "./setting.model";
export const SignatureTypes = {
    Signer: 1,
    Witness: 2,


}


export class ContractHeaderModel { //List ContractHeader
    ContractId: string;
    ContractNo: string;
    DocDate: string;
    DeptCode: string;
    DeptName: string;
    StartDate: string;
    EndDate: string;
    StatusCode: string;
    CreateBy: string;
    CreateDate: string;
    UpdateBy: string;
    UpdateDate: string;
    CreateByName: string;
    UpdateByName: string;
    TransactionType: string;
    StatusID: string;
    StatusName: string;
    StatusBgColor: string;
    Statusbordercolor: string;
    StatusFontColor: string;
    StatusFontSize: string;
    Name1: string;
    // Btn_Edit?:boolean;
    // Btn_print?:boolean;
    // ContractApproval?: ContractApproval;
    // Supplier?: Supplier;
    //old version 1st text is lower case

    ApproverType?: string;
    ApproveUserId?: number;
    ApproveDate?: string;
    ApproveStatus?: number;

}
export class UpdateStatusContract {
    ContractId: number;
    StatusID: number;
    UpdateBy:number;
    CancelReson:string;
}
export class CreateContract { //ยังmapกับ formไม่เสร็จ
    ContractId: string; //mapแล้ว
    ContractNo: string; //mapแล้ว
    DocDate: string; //mapแล้ว
    DeptCode: string; //mapแล้ว
    StatusID: string; //mapแล้ว
    StatusName: string;//Thai Name //mapแล้ว
    StatusCode: string; //Eng Name //mapแล้ว
    Subject: string; //mapแล้ว
    Remark: string; //mapแล้ว
    ReferenceContractID: string; //mapแล้ว
    ReferenceContractNo: string //mapแล้ว
    // DeptName:string;
    StartDate: string;
    EndDate: string;
    CreateBy: string;
    CreateDate: string;
    UpdateBy: string;
    UpdateDate: string;
    TransactionType: string;
    StatusBgColor: string;
    Statusbordercolor: string;
    StatusFontColor: string;
    StatusFontSize: string;
    Name1: string;
}
export class GetContractByID { //ContractHeaderByID
    
    ContractId: number;
    ContractNo: string;
    DocDate: string;
    DeptCode: string;
    DeptName: string;
    StartDate: string;;
    EndDate: string;;
    StatusCode: string;
    NameCreate?:string;
    NameUpdate?:string;
    CreateBy: number;
    CreateDate: string;;
    UpdateBy: number;
    UpdateDate: string;;
    Subject: string;
    Remark: string;
    TransactionType: string;
    StatusID: string;
    StatusName: string;
    StatusBgColor: string;
    StatusBorderColor: string;
    StatusFontColor: string;
    StatusFontSize: string;
    Name1: string;
    SupplierID?: number;
    SupplierCode: string;
    SupplierFullname: string;
    Address: string;
    SendSupplierFlag: boolean;
    ReferenceContractID?: number;
    ReferenceContractNo: string;
    City: string;
    District: string;
    PostalCode: string;
    Telephone: string;
    Street: string;
    Country: string;
    Telephone1: string;
    Telephone2: string;
    SubmitBy: number;
    SubmitDate: string;
    ManagerApproveStatus: number;
    ManagerApproveDate: string;
    SupplierApproveStatus: number;
    SupplierApproveBy: number;
    SupplierApproveDate: string;;
    RejectedReason: string;
    RejectedBy: number;
    RejectDate: string;
    HeaderApproveID: number;
    HeaderApproveStatus: number;
    HeaderRejectReason: string;
    Completemanagerflag: number;
    Contract_Detail: Contract_Detail[];
    Contract_SystemAttachDoc: ContractSystemAttachDoc[];
    Contract_Attachment: ContractAttachment[];
    Contract_Signature: ContractSignature[];

}






export class Contract_Detail {
    [x: string]: any;
    // ContractDetailID: number;
    // ContractID: number;
    // ContractTemplateID: number;
    // ContractTemplateLineNo: number;
    // LineNo: number;
    // Name: string;
    // Description: string;
    // ModifiedFlag: boolean;
    // ManagerApproveID: number;
    // ManagerApproveStatus: number;
    // ManagerRejectReason: string;
    // SupplierApproveStatus: number;
    // SupplierRejectReason: string;
    // IsHeaderFlag: boolean;
    // IsFooterFlag: boolean;
    // CreateBy: string;
    // CreateDate: Date;
    // UpdateBy: string;
    // UpdateDate: Date;

    ContractDetailID: number;
    ContractID: number;
    ContractTemplateID: number;
    ContractTemplateLineNo: number;
    LineNo: number;
    Name: string;
    Description: string;
    ModifiedFlag: boolean;
    ManagerApproveID: number;
    ManagerApproveStatus: number;
    ManagerRejectReason: string;
    SupplierApproveStatus: number;
    SupplierRejectReason: string;
    IsHeaderFlag: boolean;
    IsFooterFlag: boolean;
    CreateBy: number;
    CreateDate: Date;
    UpdateBy: number;
    UpdateDate: Date;
}


export class ContractAttachment {
    ID: number;
    ContractID: number;
    LineNo: number;
    Description: string;
    Path: string;
    FileName: string;
    CreateBy?: number;
    CreateDate?: Date;
    UpdateBy?: number;
    UpdateDate?: Date;
    Status:Number;
    File?:any;
}


export class ContractSignature {

    ID: number;
    ContractID: number;
    VendorFlag: boolean;
    LineNo: number;
    Name: string;
    Position: string;
    SignatureType: string;
    CreateBy?: number;
    CreateDate?: Date;
    UpdateBy?: number;
    UpdateDate?: Date;
   
    SCGUserID?: number;
    Email?: string;
}

export class ContractSystemAttachDoc {
    ID: number;
    ContractID: number;
    SystemAttachDocID: number;
    LineNo: number;
    DocName: string;
    CreateBy: number;
    CreateDate: Date;
    UpdateBy: number;
    UpdateDate: Date;
}

export class  ContractTemplate {
    ContractTemplateID: number;
    TemplateCode: string;
    Description: string;
    EffectiveDate: string;
    Status: number;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    // TransactionType: string;
    // StatusID: string;
    // StatusName: string;
    // StatusBgColor: string;
    // StatusBorderColor: string;
    // StatusFontColor: string;
    // StatusFontSize: string;
    ContractTemplate_Detail: ContractTemplateDetail[];
}

export class ContractTemplateDetail {
    ID: number;
    ContractTemplateID: number;
    LineNo: number;
    Name: string;
    Description: string;
    IsHeaderFlag: boolean;
    IsFooterFlag: boolean;
    UserLevelCanEdit: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class ContractApproval {
    ID: number;
    ContractID: number;
    SeqId: number;
    ApproverType: string;
    ApproveUserId: number;
    ApproveDate: Date; //string
    ApproveStatus: number;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    TransactionType: number;
    StatusID: number;
    StatusCode: string;
    StatusName: string;
    StatusBgColor: string;
    StatusBorderColor: string;
    StatusFontColor: string;
    StatusFontSize: number;
    FullName: string;
    Position: string;

    // iD: number;
    // contractID: number;
    // seqId: number;
    // approverType: string;
    // approveUserId: number;
    // approveDate: string;
    // approveStatus: number;
    // createBy: number;
    // createDate: string;
    // updateBy: number;
    // updateDate: string;
    // transactionType: number;
    // statusID: number;
    // statusCode: string;
    // statusName: string;
    // statusBgColor: string;
    // statusBorderColor: string;
    // statusFontColor: string;
    // statusFontSize: number;
    // fullName: string;
    // position: string;


}
// export class UpdateContractApproval {
//     ID: number;
//     ContractID: number;
//     SeqId: number;
//     ApproverType: string;
//     ApproveUserId: number;
//     ApproveDate: Date;
//     ApproveStatus: number;
//     CreateBy: number;
//     CreateDate: string;
//     UpdateBy: number;
//     UpdateDate: string;
//     Contract_Header: ContractHeaderModel;
//     Contract_Detail: Contract_Detail[];

// }

export class UpdateContractApproval { 

    ID: number;
    ContractID: number;
    SeqId: number;
    ApproverType: string;
    ApproveUserId: number;
    ApproveDate: Date;
    ApproveStatus: number;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    Contract_Header: UpdateContractHeader;
    Contract_Detail: Contract_Detail[];
    // ContractId: number;
    StatusID: number;
    RejectReason: string;
    // UpdateBy: number;
    HeaderApproveStatus: number;
    HeaderRejectReason: string;
}export class UpdateContractHeader {
    ContractId: number;
    StatusID: number;
    RejectReason: string;
    UpdateBy: number;
    HeaderApproveStatus: number;
    HeaderRejectReason: string;
}


export class ParamReport_Contract {
    ContractNo: string;
    DocDate: string;
    StartDate: string;
    EndDate: string;
    // Supplier:Supplier
    StatusContract:number;
    SupplierName:string;
    SupplierPosition:string;
    SupplierAddress:string;
    SupplierStreet:string;
    SupplierPostalCode:string;
    Contract_Detail: Cut_Contract_Detail[];
    Contract_Detail_Full: Contract_Detail[];
    Contract_Signature: ContractSignature[];
}

export class ContractHeader_Att { 
    
    ContractId: number;
    ContractNo: string;
    DocDate: string;
    DeptCode: string;
    DeptName: string;
    StartDate: string;;
    EndDate: string;;
    StatusCode: string;
    NameCreate?:string;
    NameUpdate?:string;
    CreateBy: number;
    CreateDate: string;;
    UpdateBy: number;
    UpdateDate: string;;
    Subject: string;
    Remark: string;
    TransactionType: string;
    StatusID: string;
    StatusName: string;
    StatusBgColor: string;
    StatusBorderColor: string;
    StatusFontColor: string;
    StatusFontSize: string;
    Name1: string;
    SupplierID?: number;
    SupplierCode: string;
    SupplierFullname: string;
    Address: string;
    SendSupplierFlag: boolean;
    ReferenceContractID?: number;
    ReferenceContractNo: string;
    City: string;
    District: string;
    PostalCode: string;
    Telephone: string;
    Street: string;
    Country: string;
    Telephone1: string;
    Telephone2: string;
    SubmitBy: number;
    SubmitDate: string;
    ManagerApproveStatus: number;
    ManagerApproveDate: string;
    SupplierApproveStatus: number;
    SupplierApproveBy: number;
    SupplierApproveDate: string;;
    RejectedReason: string;
    RejectedBy: number;
    RejectDate: string;;
    HeaderApproveID: number;
    HeaderApproveStatus: number;
    HeaderRejectReason: string;
    // Contract_Detail: Contract_Detail[];
    // Contract_SystemAttachDoc: ContractSystemAttachDoc[];
    Contract_Attachment: ContractAttachment[];
    // Contract_Signature: ContractSignature[];

}
export class Cut_Contract_Detail {
    ContractID: number;
    // ContractTemplateLineNo: number;
    // LineNo: number;
    Name: string;
    Description: string; //detail
    IsHeaderFlag: boolean;
    IsFooterFlag: boolean;
    // CreateBy: number;
    // CreateDate: Date;
    // UpdateBy: number;
    // UpdateDate: Date;
}